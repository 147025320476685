import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import { DismissSquare24Regular, Add12Regular } from '@fluentui/react-icons';
import { Divider } from '@mui/material';
import { useState, useEffect } from 'react';
import { useUpdateDisposalRequestPropsMutation, useAppendNotesMutation } from '../services/requestApi.js';
import '../services/userApi.js';
import '../services/itemApi.js';
import '../services/requestDetailsApi.js';
import { DisposalRequestStatus } from '../types/requestTypes.js';
import '../types/itemTypes.js';
import { formatDate } from '../util/dateTime.js';
import { Box } from './Box.js';
import { CommentText } from './CommentText.js';
import { BannerNotice } from './BannerNotice.js';
import { DetailsRow } from './DetailsRow.js';
import { DisposalRequestApproversPanel } from './DisposalRequestApprovers.js';
import { DisposalRequestProgressIndicator } from './DisposalRequestProgressIndicator.js';
import { DisposalRequestResponses } from './DisposalRequestResponses.js';
import { InfoPanel } from './InfoPanel.js';
import { getMessageForStatus, DisposalRequestStatusAlert } from './alert/DisposalRequestStatusAlert.js';
import { TextIconButton } from './button/TextIconButton.js';
import '../config.js';
import '../auth/config.js';
import '../auth/AuthProvider.js';
import '@azure/msal-react';
import 'react-redux';
import '@testing-library/react';
import '../themes/light.theme.js';
import '../store/store.js';
import 'redux-persist/integration/react';
import '../store/slices/applicationSlice.js';
import '../store/slices/downloadFileSlice.js';
import '../store/slices/itemModalSlice.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '@pdftron/webviewer';
import 'lodash';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../store/thunks/downloadFileThunk.js';
import { DisposalRequestStatusChip } from './chip/DisposalRequestStatusChip.js';
import './chip/ItemManagementStatusChip.js';
import './chip/PerspectiveClassChip.js';
import { RetentionClassChip } from './chip/RetentionClassChip.js';
import { UserChip } from './chip/UserChip.js';
import '@dnd-kit/utilities';
import { DetailDialog } from './dialog/DetailDialog.js';
import '@mui/lab';
import './dialog/components/item/BusinessTypeField.js';
import '@syncfusion/ej2-react-calendars';
import 'dayjs';
import './item/utils.js';
import './dialog/components/item/CustomTypeField.js';
import './dialog/components/item/PerspectiveClassField.js';
import './OptionMultiSelect.js';
import './SortableOptionList.js';
import { EditTextDialog } from './dialog/EditTextDialog.js';
import '@mui/material/Breadcrumbs';
import '@mui/material/Menu';
import '@mui/material/MenuItem';

const DisposalRequestDetailsPanel = ({ request, adminMode = false, setApprovalErrorMessage, setWarningAlertOpen, setChangeApproversDialogOpen, setIsEditApprovers, multipleRetentionClasses = false, warnings = {}, currentSelectedApprovers, }) => {
    const [commentDialogOpen, setCommentDialogOpen] = useState(false);
    const [commentDialogText, setCommentDialogText] = useState('');
    const [commentValue, setCommentValue] = useState(request?.RequestComments ?? '');
    const [changeNameDialogOpen, setChangeNameDialogOpen] = useState(false);
    const [changeCommentDialogOpen, setChangeCommentDialogOpen] = useState(false);
    const [changeNotesDialogOpen, setChangeNotesDialogOpen] = useState(false);
    const withWarning = Object.values(warnings).some((x) => x === true);
    const isNewOrRejected = adminMode && (request?.Status !== DisposalRequestStatus.New && request?.Status !== DisposalRequestStatus.Rejected);
    adminMode && (request?.Status === DisposalRequestStatus.Approved || request?.Status === DisposalRequestStatus.Complete);
    const closeChangeNameDialogHandler = () => {
        setChangeNameDialogOpen(false);
    };
    const closeChangeCommentDialogHandler = () => {
        setChangeCommentDialogOpen(false);
    };
    const closeChangeNotesDialogHandler = () => {
        setChangeNotesDialogOpen(false);
    };
    const commentBoxOpenHandler = (text) => {
        setCommentDialogOpen(true);
        setCommentDialogText(text);
    };
    const infoDialogCloseHandler = () => {
        setCommentDialogOpen(false);
        setCommentDialogText('');
    };
    const [showExceptionRejectedInOtherRequest, setShowExceptionRejectedInOtherRequest,] = useState(true);
    const [showExceptionRetentionConflict, setShowExceptionRetentionConflict] = useState(true);
    const [showApproved, setShowApproved] = useState(true);
    const [updateDisposalRequestProps] = useUpdateDisposalRequestPropsMutation();
    const [appendNotes] = useAppendNotesMutation();
    const message = getMessageForStatus(request, multipleRetentionClasses);
    const exceptionText = request.Exception;
    const approvedText = 'This Disposal Request has been fully approved and is ready to dispose.';
    const rejectedText = 'Disposal of Items in this request have been rejected.';
    // TODO: is this code needed? it's not used so commented out for now.
    // const { data: approvers, isSuccess: approversIsSuccess } =
    //   useGetApproversListQuery();
    useEffect(() => {
        setCommentValue(request?.RequestComments?.trim() ?? '');
    }, [request]);
    return (jsxs(Box, { background: 'none', style: { overflow: 'auto', height: '100%' }, children: [!!warnings.RejectedInOtherRequest &&
                showExceptionRejectedInOtherRequest &&
                adminMode && (jsx(BannerNotice, { type: 'warning', text: 'Items in this Request have reject responses in other Requests that they are assigned to.', onClose: () => setShowExceptionRejectedInOtherRequest(false), isRelative: true })), !!warnings.RetentionConflict &&
                showExceptionRetentionConflict &&
                adminMode && (jsx(BannerNotice, { type: 'warning', text: 'This Disposal Request contains Items with Retention Conflicts.  Items with conflicts will be deferred, when disposal is actioned.', onClose: () => setShowExceptionRetentionConflict(false), isRelative: true })), request.Status == 'Approved' && showApproved && adminMode && (jsx(BannerNotice, { type: 'info', text: approvedText, onClose: () => setShowApproved(false), isRelative: true })), request.Status == 'Rejected' && adminMode && (jsx(BannerNotice, { type: 'warning', text: rejectedText, onClose: () => setShowApproved(false), isRelative: true, icon: jsx(DismissSquare24Regular, { className: 'ecs-banner-notice-icon' }) })), jsx(DetailDialog, { open: commentDialogOpen, title: 'Comment', onClose: infoDialogCloseHandler, children: commentDialogText }), adminMode && (jsxs(Fragment, { children: [!(message === exceptionText ||
                        message === approvedText ||
                        message === rejectedText) && (jsx(DisposalRequestStatusAlert, { request: request, multipleRetentionClasses: multipleRetentionClasses })), jsx(InfoPanel, { style: { padding: '2.5rem 0rem 2.5rem 7rem' }, children: jsx(DisposalRequestProgressIndicator, { requestStatus: request.Status, multipleRetentionClasses: multipleRetentionClasses, withWarning: withWarning }) }), jsx(Divider, { style: {
                            margin: '0 0 1rem 7rem',
                            width: '43.625rem',
                        } })] })), jsx(EditTextDialog, { text: request?.RequestComments ?? '', label: 'Comment', open: changeCommentDialogOpen, multiline: true, onClose: closeChangeCommentDialogHandler, onSave: async (comment) => {
                    try {
                        const response = await updateDisposalRequestProps({
                            requestId: request.ID,
                            body: { RequestComments: comment },
                        });
                        if ('error' in response) {
                            const _response = response;
                            setApprovalErrorMessage(_response.error.data.FriendlyMessage);
                            setWarningAlertOpen();
                        }
                    }
                    catch (err) {
                        setWarningAlertOpen();
                    }
                    finally {
                        setChangeCommentDialogOpen(false);
                    }
                } }), jsx(EditTextDialog, { title: 'Add Notes', label: 'Notes', open: changeNotesDialogOpen, multiline: true, onClose: closeChangeNotesDialogHandler, onSave: async (notes) => {
                    try {
                        const response = await appendNotes({
                            body: { disposalRequestId: request.ID, notes: notes },
                        });
                        if ('error' in response) {
                            const _response = response;
                            setApprovalErrorMessage(_response.error.data.FriendlyMessage);
                            setWarningAlertOpen();
                        }
                        else
                            setCommentValue(response?.data?.String?.trim());
                    }
                    catch (err) {
                        setWarningAlertOpen();
                    }
                    finally {
                        setChangeNotesDialogOpen(false);
                    }
                } }), jsx(EditTextDialog, { text: request?.Name ?? '', label: 'Name', open: changeNameDialogOpen, minLength: 1, onClose: closeChangeNameDialogHandler, onSave: async (name) => {
                    try {
                        const response = await updateDisposalRequestProps({
                            requestId: request.ID,
                            body: { name: name },
                        });
                        if ('error' in response) {
                            const _response = response;
                            setApprovalErrorMessage(_response?.error?.data?.FriendlyMessage);
                            setWarningAlertOpen();
                        }
                    }
                    catch (err) {
                        setWarningAlertOpen();
                    }
                    finally {
                        setChangeNameDialogOpen(false);
                    }
                } }), jsxs(InfoPanel, { title: 'Request details', inset: '7rem', width: 46, children: [jsx(DetailsRow, { label: 'Name', data: request.Name, onEdit: adminMode &&
                            (request?.Status === DisposalRequestStatus.New ||
                                request?.Status === DisposalRequestStatus.Rejected)
                            ? () => setChangeNameDialogOpen(true)
                            : null }), jsx(DetailsRow, { label: 'Disposal Action', data: request.DisposalAction }), jsx(DetailsRow, { label: 'Requested By', data: request.RequestedBy?.DisplayName && (jsx(UserChip, { border: true, user: { name: request.RequestedBy?.DisplayName } })), alignData: true }), jsx(DetailsRow, { label: 'Status', data: jsx(DisposalRequestStatusChip, { status: request.Status }), alignData: true }), jsx(DetailsRow, { label: 'Approvers', data: ((request?.ApproverResponses?.value?.length > 0) && (currentSelectedApprovers?.length > 0)) ? (jsx(DisposalRequestApproversPanel, { approvers: currentSelectedApprovers, disposalRequest: request })) : (adminMode && (jsxs(Fragment, { children: [jsx(DisposalRequestApproversPanel, { approvers: currentSelectedApprovers, disposalRequest: request }), jsx(TextIconButton, { size: 'small', textVariant: 'body2', startIcon: jsx(Add12Regular, {}), onClick: () => {
                                        setIsEditApprovers(false);
                                        setChangeApproversDialogOpen(true);
                                    }, text: 'Add approvers' })] }))), onEdit: request?.ApproverResponses?.value?.length > 0 &&
                            currentSelectedApprovers?.length > 0 &&
                            adminMode &&
                            (request?.Status === DisposalRequestStatus.New ||
                                request?.Status === DisposalRequestStatus.Rejected)
                            ? () => {
                                setIsEditApprovers(true);
                                setChangeApproversDialogOpen(true);
                            }
                            : null, alignData: (request?.ApproverResponses?.value?.length ?? 0) === 0 && adminMode }), jsx(DetailsRow, { label: 'Date Requested', data: (adminMode && request?.Status === DisposalRequestStatus.New) ||
                            (request?.ApproverResponses?.value?.length ?? 0) === 0
                            ? ''
                            : formatDate(request?.DateRequested) }), jsx(DetailsRow, { label: 'Date Created', data: formatDate(request?.DateCreatedInEnc) }), jsx(DetailsRow, { label: 'Retention Class', data: jsx(RetentionClassChip, { classification: request.RetentionClass }), alignData: true }), jsxs("div", { style: { display: 'flex', flexDirection: 'row' }, children: [(request?.RequestComments || isNewOrRejected) ? (jsx(DetailsRow, { label: 'Comment', data: jsx(CommentText, { comment: commentValue, onClick: commentBoxOpenHandler, numberOfLines: 0 }), onEdit: adminMode &&
                                    (request?.Status === DisposalRequestStatus.New ||
                                        request?.Status === DisposalRequestStatus.Rejected)
                                    ? () => setChangeCommentDialogOpen(true)
                                    : null })) : (adminMode ? (jsx(DetailsRow, { label: 'Comment', data: jsx(TextIconButton, { size: 'small', textVariant: 'body2', startIcon: jsx(Add12Regular, {}), onClick: () => setChangeCommentDialogOpen(true), text: 'Add comment' }), alignData: true })) : (jsx(Fragment, {}))), jsx(TextIconButton, { size: 'small', textVariant: 'body2', startIcon: jsx(Add12Regular, {}), onClick: () => setChangeNotesDialogOpen(true), text: 'Add notes', style: { margin: '1.25rem 0 1.25rem 0.75rem' } })] }), jsx(Divider, { style: { margin: '1.25rem 0 1.25rem 0' } })] }), request.ApproverResponses?.value && (jsx(InfoPanel, { title: 'Responses', inset: '7rem', width: 46, children: jsx(DisposalRequestResponses, { responses: request.ApproverResponses?.value }) }))] }));
};

export { DisposalRequestDetailsPanel };

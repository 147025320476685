import { jsx } from 'react/jsx-runtime';
import { styled, Link } from '@mui/material';
import { isPerspectiveClass, isBusinessType, hasDisplayProperty, isStringALink } from '../ItemPropertiesUtil.js';
import { BusinessTypeTemplate } from '../../grid/templates/BusinesssTypeTemplate.js';
import { PerspectiveClassChip } from '../../chip/PerspectiveClassChip.js';
import { UserChip } from '../../chip/UserChip.js';
import { Chip } from '../../chip/Chip.js';
import '@fluentui/react-icons';
import '../../../types/requestTypes.js';
import '../../../types/itemTypes.js';
import 'react';
import '../../../themes/light.theme.js';
import '../../chip/ItemManagementStatusChip.js';
import { Box } from '../../Box.js';
import { Typography } from '../../Typography.js';
import { RetentionClassChip } from '../../chip/RetentionClassChip.js';
import '../../../config.js';
import '../../../auth/config.js';
import '../../../auth/AuthProvider.js';
import '@azure/msal-react';
import { formatDate } from '../../../util/dateTime.js';
import 'react-redux';
import '@testing-library/react';
import '../../../store/store.js';
import 'redux-persist/integration/react';
import '../../../store/slices/applicationSlice.js';
import '../../../store/slices/downloadFileSlice.js';
import '../../../store/slices/itemModalSlice.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '@pdftron/webviewer';
import '../../../services/itemApi.js';
import { useIcon } from '../../../hooks/useIcon.js';
import '../../../services/requestApi.js';
import '../../../services/userApi.js';
import '../../../services/requestDetailsApi.js';
import 'lodash';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../../../store/thunks/downloadFileThunk.js';

const StyledImage = styled('img') ``;
const dateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}$/;
const PropertyValueTemplate = ({ item, isEditable, }) => {
    if (isPerspectiveClass(item)) {
        return (jsx("div", { className: isEditable ? 'editable-cell' : 'noneditable-cell', children: jsx(PerspectiveClassChip, { businessClass: item }) }));
    }
    else if (isBusinessType(item)) {
        return (jsx("div", { className: isEditable ? 'editable-cell' : 'noneditable-cell', children: jsx(BusinessTypeTemplate, { title: item._Display, information: item.DerivedPath, imageId: item._ImageId }) }));
    }
    else if (hasDisplayProperty(item))
        return (jsx("div", { className: isEditable ? 'editable-cell' : 'noneditable-cell', children: jsx(UserChip, { user: { name: item._Display } }) }));
    else if (isStringALink(item)) {
        return (jsx("div", { className: isEditable ? 'editable-cell' : 'noneditable-cell', children: jsx(Link, { href: item, target: '_blank', sx: { textDecoration: 'none' }, children: jsx(Typography, { variant: 'body1', children: item }) }) }));
    }
    else if (Array.isArray(item?.value ?? item)) {
        const _itemValue = item?.value ?? item;
        const _isPerspective = _itemValue?.[0]?.['$type']?.indexOf('IT_') == 0;
        const _isRetention = _itemValue?.[0]?.['$type']?.includes('BusinessTransaction');
        const _isItemCollection = _itemValue?.[0]?.['$type']?.includes('ItemCollection');
        const getItemContent = (item, index) => {
            // Render Perspective Classes
            if (_isPerspective)
                return jsx(PerspectiveClassChip, { businessClass: item });
            // Render Retention Classes
            else if (_isRetention) {
                return (jsx(RetentionClassChip, { classification: item, sx: { width: '100%', maxWidth: 'fit-content' } }, index));
            }
            // Render Item Collection
            else if (_isItemCollection) {
                const getIcon = (id) => {
                    if (id) {
                        const { iconDetails } = useIcon({
                            imageId: id,
                        });
                        return `${process.env.REACT_APP_ENC_IMC_URL}${iconDetails?.Url}`;
                    }
                    else {
                        return `${process.env.REACT_APP_ENC_IMC_URL}BusinessClasses/Documents/document header footer.svg`;
                    }
                };
                return (jsx(Chip, { title: item?._Display, icon: jsx(StyledImage, { src: getIcon(item._ImageId), style: { height: '1.5rem' } }), isLink: true, sx: { minHeight: '2.5rem' } }, `memberOf-${index}`));
            }
        };
        return (jsx("div", { className: isEditable ? 'editable-cell' : 'noneditable-cell', children: jsx(Box, { background: 'none', direction: 'row', style: { flexWrap: 'wrap' }, gap: 'small', children: _itemValue?.map((item, index) => getItemContent(item, index)) }) }));
    }
    else {
        const dateObj = new Date(item);
        if (item &&
            dateObj &&
            !isNaN(dateObj?.getTime()) &&
            dateTimeRegex.test(item)) {
            return (jsx("div", { className: isEditable ? 'editable-cell' : 'noneditable-cell', children: jsx(Typography, { variant: 'body1', children: formatDate(item, false) }) }));
        }
        else {
            return (jsx("div", { className: isEditable ? 'editable-cell' : 'noneditable-cell', children: jsx(Typography, { variant: 'body1', children: item }) }));
        }
    }
};

export { PropertyValueTemplate, dateTimeRegex };

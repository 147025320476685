import { jsx, jsxs } from 'react/jsx-runtime';
import { styled, useTheme, Button, Divider } from '@mui/material';
import { memo, useState, useCallback, useEffect, useMemo } from 'react';
import { config } from '../config.js';
import '../types/requestTypes.js';
import { ItemManagementStatus, ItemGridQuery } from '../types/itemTypes.js';
import { insertIf } from '../util/array-utils.js';
import '../auth/config.js';
import '../auth/AuthProvider.js';
import '@azure/msal-react';
import 'dayjs';
import 'react-redux';
import '@testing-library/react';
import '../themes/light.theme.js';
import { useAppDispatch, useAppSelector } from '../store/store.js';
import 'redux-persist/integration/react';
import '../store/slices/applicationSlice.js';
import '../store/slices/downloadFileSlice.js';
import { setCurrentItem } from '../store/slices/itemModalSlice.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '@pdftron/webviewer';
import '../services/itemApi.js';
import '../services/requestApi.js';
import '../services/userApi.js';
import '../services/requestDetailsApi.js';
import { useUser, useIMCUser } from '../hooks/useUser.js';
import 'lodash';
import { useItemPanel } from '../hooks/useItemPanel.js';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import { useDownloadFile } from '../hooks/useDownloadFile.js';
import { Box } from './Box.js';
import { MemoizedFileViewer } from './FileViewer.js';
import { MemoizedItemPropertiesPanel } from './ItemPropertiesPanel.js';
import { ItemSummaryPanel } from './ItemSummaryPanel.js';
import { ItemSummaryTitle } from './ItemSummaryTitle.js';
import { TabPanel } from './TabPanel.js';
import { Alert } from './alert/Alert.js';
import { ColumnEdit20Regular } from '@fluentui/react-icons';
import { DisposalDetailsPanel } from './DisposalDetailsPanel.js';
import './grid/components/PageSizeDropdown.js';
import './grid/components/PageSelector.js';
import '@mui/system';
import '@syncfusion/ej2-data';
import '@syncfusion/ej2-react-grids';
import './chip/ItemManagementStatusChip.js';
import './chip/PerspectiveClassChip.js';
import './grid/templates/CommentTemplate.js';
import './grid/templates/ItemSummaryTemplate.js';
import './grid/templates/PagerTemplate.js';
import './grid/templates/RetentionClassTemplate.js';
import './grid/templates/SummaryTemplate.js';
import './grid/templates/DisposalStatusTemplate.js';
import { ItemsGrid } from './grid/ItemsGrid.js';
import { getQueryProps } from './item/utils.js';

const StyledImage = styled('img') ``;
/**
 * ItemPanel component to display details of an item
 * @param id - id of the Item
 * @param tab - selected tab ('summary' etc.,)
 * @event onSelectTab - select tab event
 * @returns ItemPanel JSX.Element
 */
const ItemPanel = memo(({ initialId = '', id, initialTab, onSelectTab, adminMode, disposalRequest, setId, clearLevels = false }) => {
    // -----------------------------
    // Theme and utility hooks
    // -----------------------------
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const levels = useAppSelector((state) => state.itemModal.navigation.levels);
    const currId = levels?.[levels?.length - 1]?.itemId ?? id;
    const { iconDetails, itemDetails, selectedTab, formDetails, handleSelectTab, propertyDetails, treeGridProperties, businessTypeProperties, businessTypeIconDetails, businessTypeTreeGridData, businessTypePropertyValues, itemCollectionCount, showColumnChooser, handleCheckbox, handleOpen, handleOpenURL, gridRef, selectedCheckbox, fullScreenTab, handleOpenItems, hasPreviewableContent, setPreviewState, handleChangeTab, enums, perspectiveClasses, } = useItemPanel({ id: currId, initialTab, onSelectTab, disposalRequest, setId, levels });
    const [selectedRowHighlight, setSelectedRowHighlight] = useState([]);
    const [alertError, setAlertOpen] = useState(false);
    const [alertSuccess, setSuccessOpen] = useState(false);
    const stringToCopy = `${config.REACT_APP_ENC_LANDING_APP_URL}items/${itemDetails?.ID}`;
    const copyErrorMessage = 'An error occurred while copying this link to clipboard';
    const copySuccessMessage = 'Link to this item has been copied to clipboard';
    const { downloadFile } = useDownloadFile();
    const user = useUser();
    const queryPropsResult = getQueryProps();
    const imcUser = queryPropsResult ? useIMCUser(queryPropsResult) : null;
    let canIMCUSerEdit = false;
    if (imcUser) {
        canIMCUSerEdit = imcUser.UserAccessType === 'Full' ? true : false;
    }
    const userPermission = user?.UserAccessType === 'Full' ?? false;
    const hasEditPermission = canIMCUSerEdit ||
        (adminMode &&
            userPermission &&
            itemDetails?.Status !== ItemManagementStatus.Destroyed);
    const rowSelected = useCallback((rowData) => {
        if (rowData?.data?.highlightData?.length > 0) {
            setSelectedRowHighlight(rowData.data.highlightData);
        }
    }, []);
    // Makes sure that every time an item is opened in IMC, the tabs and items are reset
    useEffect(() => {
        if (clearLevels) {
            handleChangeTab('summary');
            dispatch(setCurrentItem(undefined));
        }
    }, [initialId]);
    // Sets the initial level value in IMC
    useEffect(() => {
        if (!levels?.length && clearLevels && itemDetails?.ID) {
            dispatch(setCurrentItem({ id: itemDetails.ID, itemId: itemDetails.ID, name: itemDetails.DisplayName }));
        }
    }, [itemDetails]);
    const handleCopyItemLink = () => {
        navigator.clipboard
            .writeText(stringToCopy)
            .then(() => {
            setSuccessOpen(true);
        })
            .catch((err) => {
            setAlertOpen(true);
        });
    };
    const MemoizedItemsGrid = useMemo(() => {
        return (jsx(ItemsGrid, { isItemPanel: true, ref: gridRef, adminMode: adminMode, onOpen: handleOpen, onOpenURL: handleOpenURL, onOpenItems: handleOpenItems, onSelect: handleCheckbox, disposalId: id, disposalRequest: disposalRequest, itemGridType: ItemGridQuery.ChildWithStatus, selectedRecords: selectedCheckbox, persistanceId: `panelGrid${JSON.stringify(levels).length}-4`, isItemCollection: true, levels: levels }));
    }, [id, levels]);
    return (jsxs(Box, { background: 'none', children: [jsx(ItemSummaryTitle, { isMoreButtonAvailable: itemDetails?.HasCapturedFile, icon: iconDetails ? (jsx(StyledImage, { src: `${process.env.REACT_APP_ENC_IMC_URL}${iconDetails?.Url}`, alt: iconDetails?.AltText, style: { height: '1.5rem' } })) : null, displayName: itemDetails?.DisplayName, itemType: itemDetails?.TypeDef?._Display, businessType: itemDetails?.BusinessType?._Display, onDownloadClick: () => {
                    downloadFile({
                        id,
                        itemDetails,
                    });
                }, onCopyLinkClick: () => {
                    handleCopyItemLink();
                } }), jsxs(Box, { direction: 'row', background: 'none', children: [jsx(Box, { background: 'none', width: !fullScreenTab ? '50%' : '100%', children: jsx(TabPanel, { selected: selectedTab, onSelect: handleSelectTab, inset: '3.5rem', tabs: [
                                {
                                    title: 'Summary',
                                    value: 'summary',
                                    children: (jsx(ItemSummaryPanel, { item: itemDetails, disposalRequest: disposalRequest, hasEditPermission: hasEditPermission, treeGridProperties: treeGridProperties, businessTypeProperties: businessTypeProperties, businessTypeIconDetails: businessTypeIconDetails, businessTypePropertyValues: businessTypePropertyValues, onOpen: handleOpen })),
                                },
                                {
                                    title: 'Properties',
                                    value: 'properties',
                                    children: (jsx(MemoizedItemPropertiesPanel, { selectedTab: selectedTab, formDetails: formDetails, propertyDetails: propertyDetails, hasEditPermission: hasEditPermission, businessTypeTreeGridData: businessTypeTreeGridData, rowSelected: rowSelected, itemTypeName: itemDetails?.TypeDef?._Display
                                            ? `${itemDetails?.TypeDef?._Display} Properties`
                                            : undefined, businessTypeName: itemDetails?.BusinessType?._Display
                                            ? `${itemDetails?.BusinessType?._Display} Properties`
                                            : undefined, enums: enums, perspectiveClasses: perspectiveClasses })),
                                },
                                {
                                    title: 'Disposal Details',
                                    value: 'disposalDetails',
                                    children: jsx(DisposalDetailsPanel, { itemId: itemDetails?.ID }),
                                },
                                ...insertIf(itemCollectionCount?.count &&
                                    (itemDetails?.AttachedItems?.value.length > 0 ||
                                        itemDetails?.TypeDef?._Display == 'Item Collection'), {
                                    title: `${itemCollectionCount?.count} ${itemDetails.AttachedItems?.value?.length > 0
                                        ? 'attachment'
                                        : 'item'}${itemCollectionCount?.count != 1 ? `s` : ``}`,
                                    value: 'items',
                                    children: (jsxs(Box, { padding: 'large', background: 'none', style: {
                                            paddingTop: '0.6rem',
                                            height: '100%',
                                        }, children: [jsx(Box, { direction: 'row', alignItems: 'center', background: 'none', height: 5, children: jsx(Box, { background: 'none', gap: 'small', direction: 'row', justifyContent: 'end', children: jsx(Button, { variant: 'text', startIcon: jsx(ColumnEdit20Regular, {}), onClick: showColumnChooser, children: "Change Columns" }) }) }), jsx(Box, { background: 'none', className: 'item-panel-items-grid', style: {
                                                    paddingTop: '0.6rem',
                                                    height: '100%',
                                                }, children: MemoizedItemsGrid })] })),
                                }),
                            ] }) }), !fullScreenTab && (jsxs(Box, { width: '50%', height: 'calc(100% - 2rem)', alignItems: 'center', justifyContent: 'center', style: {
                            backgroundColor: theme.palette.info[100],
                            marginTop: '2rem',
                        }, children: [hasPreviewableContent && jsx(Divider, { style: { width: '100%' } }), jsx(MemoizedFileViewer, { id: id, itemDetails: itemDetails, rowData: selectedRowHighlight, setPreviewState: setPreviewState })] }))] }), jsx(Alert, { alertType: 'warning', message: copyErrorMessage, open: alertError, onClose: () => {
                    setAlertOpen(false);
                }, autoHideDuration: 10000 }), jsx(Alert, { alertType: 'success', message: copySuccessMessage, open: alertSuccess, onClose: () => {
                    setSuccessOpen(false);
                }, autoHideDuration: 10000 })] }));
});
const MemoizedItemPanel = memo(ItemPanel);

export { ItemPanel, MemoizedItemPanel };

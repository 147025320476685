import { jsxs, jsx } from 'react/jsx-runtime';
import { PeopleEdit20Regular, ErrorCircle16Regular } from '@fluentui/react-icons';
import { useTheme, Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useApprovers } from '../../hooks/useApprovers.js';
import '../../config.js';
import '../../types/requestTypes.js';
import '../../types/itemTypes.js';
import '../../auth/config.js';
import '../../auth/AuthProvider.js';
import '@azure/msal-react';
import 'dayjs';
import 'react-redux';
import '@testing-library/react';
import '../../themes/light.theme.js';
import '../../store/store.js';
import 'redux-persist/integration/react';
import '../../store/slices/applicationSlice.js';
import '../../store/slices/downloadFileSlice.js';
import '../../store/slices/itemModalSlice.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '@pdftron/webviewer';
import '../../services/itemApi.js';
import '../../services/requestApi.js';
import '../../services/userApi.js';
import '../../services/requestDetailsApi.js';
import 'lodash';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../../store/thunks/downloadFileThunk.js';
import { Box as Box$1 } from '../Box.js';
import { EditApprovers } from '../EditApprovers.js';
import { CustomActionModal } from './CustomActionModal.js';
import { ActionButton } from './components/ActionButton.js';

const ChangeApproversDialog = ({ title = 'Change Approvers', approvers, disposalRequest, open, onClose, onAction, currentSelectedApprovers, }) => {
    const { selectedApprovers, setSelectedApprovers, isApproversListValid, setIsApproversListValid, } = useApprovers(currentSelectedApprovers);
    const theme = useTheme();
    useEffect(() => {
        if ((currentSelectedApprovers.length ?? 0) === 0)
            setSelectedApprovers([]);
        setSelectedApprovers(currentSelectedApprovers);
    }, [currentSelectedApprovers]);
    useEffect(() => {
        disposalRequest?.ApproverResponses?.value.forEach((_approverResponse) => {
            if (!currentSelectedApprovers.some((e) => e.ID === _approverResponse.Approver.ID))
                currentSelectedApprovers.push({
                    _Display: _approverResponse.Approver?.DisplayName,
                    ID: _approverResponse.Approver?.ID,
                    Department: '',
                    Status: _approverResponse?.Status,
                    ApproverId: _approverResponse?.ID,
                });
        });
    }, [approvers]);
    const handleResetApprovers = () => {
        setSelectedApprovers(currentSelectedApprovers);
        onClose();
    };
    return (jsxs(CustomActionModal, { title: title, onClose: handleResetApprovers, open: open, icon: jsx(PeopleEdit20Regular, {}), size: 'large', actionButton: jsx(ActionButton, { onAction: (name, comment, approvers) => onAction(approvers), selectedApprovers: selectedApprovers, setIsValidCondition: (approvers) => (approvers?.length ?? 0) > 0, setValidity: setIsApproversListValid, label: 'Change Approvers' }), style: { width: '35rem', minWidth: '35rem' }, children: [jsxs(Box, { mb: 2, children: [jsx(Typography, { variant: 'h4', children: "Approvers" }), jsx(Typography, { variant: 'body2', children: "Specify Approvers in approval order. Note that removing an Approver that has responded, will delete their response." })] }), jsx(EditApprovers, { approvers: approvers, setSelectedApprovers: setSelectedApprovers, selectedApprovers: selectedApprovers, isApproversListValid: isApproversListValid, setIsApproversListValid: setIsApproversListValid, isDisposalRequestRejected: disposalRequest?.Status === 'Rejected' }), !isApproversListValid && (jsxs(Box$1, { background: 'none', color: 'red', direction: 'row', style: {
                    marginTop: '0.75rem',
                }, children: [jsx(ErrorCircle16Regular, { color: theme.palette.warning.main, style: {
                            marginRight: '.3rem',
                        } }), jsx(Typography, { variant: 'body3', color: theme.palette.warning.main, children: "Please specify approvers" })] }))] }));
};

export { ChangeApproversDialog };

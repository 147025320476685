import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { useState } from 'react';
import { Divider, Typography } from '@mui/material';
import { DisposalRequestStatus } from '../types/requestTypes.js';
import { ItemManagementStatus } from '../types/itemTypes.js';
import { Box } from './Box.js';
import '../auth/config.js';
import '../config.js';
import '../auth/AuthProvider.js';
import '@azure/msal-react';
import { formatDate } from '../util/dateTime.js';
import 'react-redux';
import '@testing-library/react';
import '../themes/light.theme.js';
import '../store/store.js';
import 'redux-persist/integration/react';
import '../store/slices/applicationSlice.js';
import '../store/slices/downloadFileSlice.js';
import '../store/slices/itemModalSlice.js';
import { DetailsRow } from './DetailsRow.js';
import '../services/requestApi.js';
import '../services/userApi.js';
import { useGetItemDisposalDetailsQuery } from '../services/itemApi.js';
import '../services/requestDetailsApi.js';
import { DisposalRequestApprovers } from './DisposalRequestApprovers.js';
import { InfoPanel } from './InfoPanel.js';
import { Chip } from './chip/Chip.js';
import { New20Regular, PeopleQueue20Regular, CheckmarkSquare20Regular, DismissSquare20Regular, ArrowClockwiseDashes20Regular, Delete20Regular, ErrorCircle20Regular } from '@fluentui/react-icons';
import { DisposalRequestStatusChip } from './chip/DisposalRequestStatusChip.js';
import { DisposalRequestStatusIndicator } from './chip/DisposalRequestStatusIndicator.js';
import './chip/ItemManagementStatusChip.js';
import './chip/PerspectiveClassChip.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '@pdftron/webviewer';
import 'lodash';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../store/thunks/downloadFileThunk.js';
import { CommentText } from './CommentText.js';
import '@dnd-kit/utilities';
import { DetailDialog } from './dialog/DetailDialog.js';
import '@mui/lab';
import './dialog/components/item/BusinessTypeField.js';
import '@syncfusion/ej2-react-calendars';
import 'dayjs';
import './item/utils.js';
import './dialog/components/item/CustomTypeField.js';
import './dialog/components/item/PerspectiveClassField.js';
import './OptionMultiSelect.js';
import './SortableOptionList.js';
import '@mui/material/Breadcrumbs';
import '@mui/material/Menu';
import '@mui/material/MenuItem';

const DisposalDetailsPanel = ({ itemId, }) => {
    const [addtlInfoDialogOpen, setAddtlInfoDialogOpen] = useState(false);
    const [addtlInfoDialogText, setAddtlInfoDialogText] = useState('');
    const { data: itemDisposalDetails, } = useGetItemDisposalDetailsQuery({ itemId: itemId });
    const _icon = {
        [DisposalRequestStatus.New]: jsx(New20Regular, {}),
        [DisposalRequestStatus.InApproval]: jsx(PeopleQueue20Regular, {}),
        [DisposalRequestStatus.Approved]: jsx(CheckmarkSquare20Regular, {}),
        [DisposalRequestStatus.Rejected]: jsx(DismissSquare20Regular, {}),
        [DisposalRequestStatus.InProgress]: jsx(ArrowClockwiseDashes20Regular, {}),
        [DisposalRequestStatus.Complete]: jsx(Delete20Regular, {}),
        [DisposalRequestStatus.Failed]: jsx(ErrorCircle20Regular, {}),
    };
    // TODO: clean up below (for demo)
    const dateCompleted = itemDisposalDetails?.DisposalDetails?.value[0]?.CompletedDate;
    const addtlInfoDialogOpenHandler = (text) => {
        setAddtlInfoDialogOpen(true);
        setAddtlInfoDialogText(text);
    };
    const addtlInfoDialogCloseHandler = () => {
        setAddtlInfoDialogOpen(false);
        setAddtlInfoDialogText('');
    };
    const getDisposedByText = () => {
        let disposedByValue = '';
        itemDisposalDetails?.DisposalDetails?.value?.map((disposalDetails, index) => {
            const actionedById = disposalDetails?.DisposalRequest?.ActionedById;
            let actionedByName = '';
            disposalDetails?.DisposalRequest?.ApproverResponses.value.forEach((approvers) => {
                if (approvers.ApproverId === actionedById)
                    actionedByName = approvers.Approver.DisplayName;
            });
            disposedByValue += `${actionedByName} (${index + 1})\r\n`;
        });
        return disposedByValue;
    };
    return (jsxs(Box, { padding: 'none', background: 'none', direction: 'row', gap: 'none', height: '100%', children: [jsx(DetailDialog, { open: addtlInfoDialogOpen, title: 'Additional Information', onClose: addtlInfoDialogCloseHandler, children: addtlInfoDialogText }), jsxs(Box, { background: 'none', width: '100%', style: { overflow: 'overlay', paddingRight: '1rem', overflowY: 'auto' }, children: [itemDisposalDetails && itemDisposalDetails.Status === ItemManagementStatus.Destroyed && (jsxs(Fragment, { children: [jsxs(InfoPanel, { inset: '3.5rem', title: 'Disposal Details', style: { paddingRight: '5.25rem', paddingTop: '0.988rem' }, children: [jsx(DetailsRow, { label: 'Date Disposed', data: formatDate(dateCompleted) }), jsx(DetailsRow, { label: 'Disposed by', data: getDisposedByText() }), jsx(DetailsRow, { label: 'Disposal Action', data: 'Destroy' }), jsx(DetailsRow, { label: 'Additional Information', data: jsx(CommentText, { comment: itemDisposalDetails?.DisposalDetails.value &&
                                                itemDisposalDetails.DisposalDetails.value[0]?.AdditionalInformation, onClick: addtlInfoDialogOpenHandler }) })] }), jsx(Divider, { style: {
                                    width: '88%',
                                    margin: '0 auto',
                                    borderColor: '#DCE1EF',
                                } })] })), itemDisposalDetails?.DisposalDetails ? (jsx(InfoPanel, { inset: '3.5rem', title: 'Disposal Approval', style: { paddingRight: '5.25rem', paddingTop: '0.988rem' }, children: itemDisposalDetails?.DisposalDetails?.value.map((item, index) => (jsxs(Box, { background: 'none', style: { overflow: 'auto', padding: '0 0 2rem 0', flex: 'none' }, children: [jsx(DetailsRow, { label: 'Disposal Requests', data: jsx(Chip, { border: true, title: item.DisposalRequest?.Name, subTitle: item.DisposalRequest?.Name, icon: _icon[item.DisposalRequest?.Status], internalChip: jsx(DisposalRequestStatusIndicator, { status: item.DisposalRequest?.Status }) }, index) }), jsx(DetailsRow, { label: 'Approvers', data: (item.DisposalRequest?.ApproverResponses?.value?.length ?? 0) > 0 ? (jsx(DisposalRequestApprovers, { responses: item.DisposalRequest?.ApproverResponses?.value, width: 24 })) : (jsx(Fragment, { children: jsx(DisposalRequestStatusChip, { status: item.DisposalRequest?.Status }) })) })] }, index))) })) : (jsx(Box, { background: 'none', style: { padding: '3rem 0 3rem 5.25rem' }, children: jsx(Typography, { variant: 'body1', children: "No Disposal Details available" }) }))] })] }));
};

export { DisposalDetailsPanel };

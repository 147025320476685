import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { memo } from 'react';
import { Box } from './Box.js';
import { SearchField } from './SearchField.js';
import { FilterDropdown } from './FilterDropdown.js';
import { isDate, isEnum, isDouble, isBoolean, isInteger, isDateTime, isEntity } from './item/utils.js';
import { DatePicker, DateTimePicker } from '@syncfusion/ej2-react-calendars';
import { getEnumValues, getPerspectiveClassValues } from './dialog/components/item/customTypeUtils.js';
import { DropDownList, AutoComplete } from '@syncfusion/ej2-react-dropdowns';
import { NumericTextBox } from '@syncfusion/ej2-react-inputs';
import { useItemPropertiesPanel, Field } from '../hooks/useItemPropertiesPanel.js';
import { MemoizedItemPropertiesGrid } from './grid/ItemPropertiesGrid.js';
import '@mui/material';
import '@fluentui/react-icons';
import '../config.js';
import '../types/requestTypes.js';
import '../types/itemTypes.js';
import '../auth/config.js';
import '../auth/AuthProvider.js';
import '@azure/msal-react';
import { formatDate } from '../util/dateTime.js';
import 'react-redux';
import '@testing-library/react';
import '../themes/light.theme.js';
import '../store/store.js';
import 'redux-persist/integration/react';
import '../store/slices/applicationSlice.js';
import '../store/slices/downloadFileSlice.js';
import '../store/slices/itemModalSlice.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '@pdftron/webviewer';
import '../services/itemApi.js';
import '../services/requestApi.js';
import '../services/userApi.js';
import '../services/requestDetailsApi.js';
import 'lodash';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../store/thunks/downloadFileThunk.js';
import '@dnd-kit/utilities';
import '@mui/lab';
import './chip/ItemManagementStatusChip.js';
import './chip/PerspectiveClassChip.js';
import './dialog/components/item/BusinessTypeField.js';
import 'dayjs';
import { EditBusinessTypeDialog } from './dialog/EditBusinessTypeDialog.js';
import './dialog/components/item/CustomTypeField.js';
import './dialog/components/item/PerspectiveClassField.js';
import { EditPerspectiveClassesDialog } from './dialog/EditPerspectiveClassesDialog.js';
import { EditRetentionClassesDialog } from './dialog/EditRetentionClassesDialog.js';
import './OptionMultiSelect.js';
import '@mui/material/Breadcrumbs';
import '@mui/material/Menu';
import '@mui/material/MenuItem';
import './item/ItemBusinessTypePanel.js';
import { Dialog } from './item/ItemGovernancePanel.js';
import './item/type/ItemTypeEmailPanel.js';

const ItemPropertiesPanel = ({ hasEditPermission, formDetails, itemTypeName, propertyDetails, businessTypeName, businessTypeTreeGridData, rowSelected, enums, perspectiveClasses: _perspectiveClasses, }) => {
    const item = propertyDetails?.value?.[0];
    const menuOptions = [businessTypeName, itemTypeName, 'All Properties'];
    const propLinksData = propertyDetails?.ResultPropLinks;
    const { gridRef, menuItem, dialogDetails, onChange, handleSearch, handleDialogSave, handleDialogClose, handleRowSelecting, handleRowSelection, handleActionComplete, handleRecordDoubleClick, } = useItemPropertiesPanel({
        item,
        hasEditPermission,
        formDetails,
        selectedTab: '',
        itemTypeName,
        propLinksData,
        businessTypeName,
        businessTypeTreeGridData,
        rowSelected,
    });
    let elem;
    let textField;
    let enumField;
    let doubleField;
    let booleanField;
    let integerField;
    let datePickerField;
    let selectedField = '';
    let dateTimePickerField;
    let perspectiveClassField;
    let selectedBusinessType = null;
    let selectedRetentionClasses = [];
    let selectedPerspectiveClass = null;
    let selectedPerspectiveClasses = [];
    const editOptions = {
        allowAdding: true,
        allowDeleting: true,
        allowEditing: true,
        mode: 'Cell',
        newRowPosition: 'Below',
    };
    const editTemplate = {
        create: () => {
            elem = document.createElement('input');
            return elem;
        },
        read: (args) => {
            switch (selectedField) {
                case Field.PerspectiveClass:
                    return selectedPerspectiveClass;
                case Field.Boolean:
                    return booleanField?.value;
                case Field.BusinessType:
                    return selectedBusinessType;
                case Field.BusinessClasses:
                    return selectedPerspectiveClasses;
                case Field.Classifications:
                    return selectedRetentionClasses;
                default:
                    return args?.value?.value || args?.value;
            }
        },
        write: (args) => {
            const { rowData } = args;
            const { propDef } = rowData;
            selectedField = '';
            const isDateType = isDate(propDef);
            const isEnumType = isEnum(propDef);
            const isDoubleType = isDouble(propDef);
            const isBooleanType = isBoolean(propDef);
            const isIntegerType = isInteger(propDef);
            const isDateTimeType = isDateTime(propDef);
            const isPerspectiveClassType = isEntity(propDef) &&
                propDef?.PropTypeDef?.Name?.indexOf('IT_') === 0 &&
                propDef?.PropTypeDef?.Category?.InternalName === 'Inheritance';
            if (isDateType) {
                const value = args.rowData[args.column.field];
                datePickerField = new DatePicker({
                    value: value ? new Date(formatDate(value)) : null,
                    format: 'yyyy-MM-dd',
                    showTodayButton: false,
                    showClearButton: false,
                    cssClass: 'custom-type-datepicker',
                    allowEdit: false,
                });
                datePickerField.appendTo(elem);
            }
            else if (isDateTimeType) {
                const value = args.rowData[args.column.field];
                dateTimePickerField = new DateTimePicker({
                    value: value ? new Date(formatDate(value)) : null,
                    format: 'yyyy-MM-ddT00:00:00',
                    showTodayButton: false,
                    showClearButton: false,
                    cssClass: 'custom-type-datepicker',
                    allowEdit: false,
                });
                dateTimePickerField.appendTo(elem);
            }
            else if (isEnumType) {
                const value = args.rowData[args.column.field];
                const { Caption } = propDef;
                const enumValues = getEnumValues(enums, Caption);
                const sortOrder = 'Ascending';
                enumField = new DropDownList({
                    value,
                    fields: { text: 'Caption', value: 'name' },
                    dataSource: enumValues,
                    cssClass: 'custom-type-dropdown',
                    sortOrder,
                    popupHeight: '245px',
                });
                enumField.appendTo(elem);
            }
            else if (isIntegerType) {
                const value = args.rowData[args.column.field];
                integerField = new NumericTextBox({
                    value,
                    format: '####',
                    cssClass: 'custom-type-numeric',
                });
                integerField.appendTo(elem);
            }
            else if (isDoubleType) {
                const value = args.rowData[args.column.field];
                doubleField = new NumericTextBox({
                    value,
                    format: '####.00',
                    decimals: 2,
                    cssClass: 'custom-type-numeric',
                });
                doubleField.appendTo(elem);
            }
            else if (isBooleanType) {
                const value = args.rowData[args.column.field];
                selectedField = Field.Boolean;
                booleanField = new DropDownList({
                    value: value?.toString(),
                    fields: { text: 'label', value: 'value' },
                    dataSource: [
                        { label: '', value: '' },
                        { label: 'Yes', value: 'true' },
                        { label: 'No', value: 'false' },
                    ],
                    cssClass: 'custom-type-dropdown',
                });
                booleanField.appendTo(elem);
            }
            else if (isPerspectiveClassType) {
                let value = args.rowData[args.column.field];
                selectedField = Field.PerspectiveClass;
                const propTypeDefId = propDef?.PropTypeDefId;
                const perspectiveClasses = getPerspectiveClassValues(_perspectiveClasses, propTypeDefId, '');
                const perspectiveClassValue = perspectiveClasses?.find((x) => x?._Display === value?._Display);
                if (value)
                    selectedPerspectiveClass = perspectiveClassValue;
                else {
                    value = {
                        ID: null,
                        TypeDefId: null,
                        Active: true,
                    };
                }
                perspectiveClassField = new DropDownList({
                    value: perspectiveClassValue?.ID,
                    dataSource: perspectiveClasses,
                    fields: {
                        text: '_Display',
                        value: 'ID',
                    },
                    select: (res) => {
                        perspectiveClasses.forEach((val) => {
                            if (val.ID === res?.itemData?.ID ||
                                (!val.ID && !res?.itemData?.ID)) {
                                selectedPerspectiveClass = val;
                            }
                        });
                    },
                    cssClass: 'custom-type-dropdown',
                });
                perspectiveClassField.appendTo(elem);
            }
            else {
                // TODO: update the component to TextBox, currently, there's an issue on inline editing.
                const value = args.rowData[args.column.field];
                textField = new AutoComplete({
                    value,
                    showClearButton: false,
                    zIndex: -1000,
                });
                textField.appendTo(elem);
            }
        },
    };
    return (jsxs(Fragment, { children: [jsxs(Box, { padding: 'large', background: 'none', style: {
                    paddingLeft: '1.75rem',
                    paddingTop: '1.188rem',
                }, children: [jsxs(Box, { direction: 'row', justifyContent: 'space-between', alignItems: 'center', background: 'none', style: { marginBottom: '1.188rem', paddingTop: '0.625rem' }, children: [jsx(SearchField, { width: '17.188rem', height: '3rem', onHandleSearch: handleSearch }), jsx(FilterDropdown, { value: menuItem, onChange: onChange, menuOptions: menuOptions })] }), jsx(Box, { background: 'none', children: jsx(MemoizedItemPropertiesGrid, { gridRef: gridRef, menuItem: menuItem, formDetails: formDetails, editOptions: editOptions, itemTypeName: itemTypeName, editTemplate: editTemplate, hasEditPermission: hasEditPermission, handleRowSelection: handleRowSelection, handleActionComplete: handleActionComplete, handleRowSelecting: handleRowSelecting, handleRecordDoubleClick: handleRecordDoubleClick }) })] }), dialogDetails.dialog === Dialog.PERSPECTIVE && (jsx(EditPerspectiveClassesDialog, { onSave: handleDialogSave, onClose: handleDialogClose, open: dialogDetails.open, title: dialogDetails.title, fieldLabel: dialogDetails.fieldLabel, propertyName: dialogDetails.propertyName, propertyValue: dialogDetails.propertyValue })), dialogDetails.dialog === Dialog.RETENTION && (jsx(EditRetentionClassesDialog, { onSave: handleDialogSave, onClose: handleDialogClose, open: dialogDetails.open, title: dialogDetails.title, fieldLabel: dialogDetails.fieldLabel, propertyName: dialogDetails.propertyName, propertyValue: dialogDetails.propertyValue })), dialogDetails.dialog === Dialog.BUSINESS_TYPE && (jsx(EditBusinessTypeDialog, { onSave: handleDialogSave, onClose: handleDialogClose, open: dialogDetails.open, title: dialogDetails.title, fieldLabel: dialogDetails.fieldLabel, propertyName: dialogDetails.propertyName, propertyDetails: dialogDetails.propertyValue }))] }));
};
const MemoizedItemPropertiesPanel = memo(ItemPropertiesPanel);

export { ItemPropertiesPanel, MemoizedItemPropertiesPanel };

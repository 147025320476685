import { jsx } from 'react/jsx-runtime';
import { styled } from '@mui/material';
import 'react';
import '../../config.js';
import '../../types/requestTypes.js';
import '../../types/itemTypes.js';
import '../../auth/config.js';
import '../../auth/AuthProvider.js';
import '@azure/msal-react';
import 'dayjs';
import 'react-redux';
import '@testing-library/react';
import '../../themes/light.theme.js';
import '../../store/store.js';
import 'redux-persist/integration/react';
import '../../store/slices/applicationSlice.js';
import '../../store/slices/downloadFileSlice.js';
import '../../store/slices/itemModalSlice.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '@pdftron/webviewer';
import { useGetPerspectiveClassQuery } from '../../services/itemApi.js';
import { useIcon } from '../../hooks/useIcon.js';
import '../../services/requestApi.js';
import '../../services/userApi.js';
import '../../services/requestDetailsApi.js';
import 'lodash';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../../store/thunks/downloadFileThunk.js';
import { RequestStatusChip } from './RequestStatusChip.js';

const StyledImage = styled('img') ``;
const PerspectiveClassChip = ({ businessClass, }) => {
    // purpose of this code is to display the latest perspective class name
    // there's an issue on the business object that the data is not getting the latest value for perspective class.
    const { data: perspectiveClass } = useGetPerspectiveClassQuery({
        perspectiveClassId: businessClass?.ID,
    }, { skip: !businessClass?.ID });
    const imageId = perspectiveClass?._ImageId;
    const typeDefId = perspectiveClass?.TypeDefId;
    const { iconDetails } = useIcon({
        imageId,
        typeDefId,
    });
    return (jsx(RequestStatusChip, { "data-testid": `perspective-class-chip-${businessClass?.ID}`, title: perspectiveClass?.Name, icon: iconDetails ? (jsx(StyledImage, { src: `${process.env.REACT_APP_ENC_IMC_URL}${iconDetails?.Url}`, alt: iconDetails?.AltText, style: { height: '1.5rem', width: '1.5rem' } })) : null, subTitle: perspectiveClass?.FullPath ? perspectiveClass?.FullPath : undefined, style: { textAlign: 'left', width: 'fit-content' } }));
};

export { PerspectiveClassChip };

import { useState, useRef, useEffect } from 'react';
import { config } from '../config.js';
import '../types/requestTypes.js';
import { ItemManagementStatus } from '../types/itemTypes.js';
import 'react/jsx-runtime';
import { acquireToken } from '../util/data-utils.js';
import 'dayjs';
import 'react-redux';
import '@testing-library/react';
import '@mui/material';
import '../themes/light.theme.js';
import '../store/store.js';
import 'redux-persist/integration/react';
import '../store/slices/applicationSlice.js';
import '../store/slices/downloadFileSlice.js';
import '../store/slices/itemModalSlice.js';
import { initializeVideoViewer } from '@pdftron/webviewer-video';
import { initializeAudioViewer } from '@pdftron/webviewer-audio';
import WebViewer from '@pdftron/webviewer';

// https://docs.apryse.com/documentation/web/guides/file-format-support/
const APRYSE_WEBVIEWER_SUPPORTED_FILES = [
    'pdf',
    'fdf',
    'xfdf',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'ppt',
    'pptx',
    'rtf',
    'odt',
    'ods',
    'odp',
    'wpf',
    'jpg',
    'jfif',
    'png',
    'tif', // IMAGE FORMATS
];
const APRYSE_WEBVIEWER_SUPPORTED_MEDIA = [
    'mp4',
    'ogg',
    'webm',
    'mp3',
    'wab',
    'flac',
];
const useFileDetails = ({ id, item, viewer, rowData, setAlertOpen }) => {
    const [fileDetails, setFileDetails] = useState();
    const [htmlData, setHtmlData] = useState('');
    const [docBlobUrl, setDocBlobUrl] = useState('');
    const [pdfJsonBlobUrl, setPdfJsonBlobUrl] = useState(null);
    const [error, setError] = useState(null);
    const [hasInitialized, setHasInitialized] = useState(false);
    const instanceRef = useRef(null);
    const typeDefId = item?.TypeDefId;
    const itemId = item?.ID;
    const fileExtension = item?.StorageObject?.FileExtension;
    const referenceType = item?.TypeDef?.Name;
    const eigenDisplayViewContentId = item?.EigenDisplayViewContentId;
    const licenseKey = 'EncompaaS Software Ltd (encompaas.cloud):OEM:Encompaas::B+:AMS(20240509):D7A59CCD0497C60A0360B13AC982537860612F9DE740EDA8DD047B8A9D242E8E22CAB6F5C7';
    useEffect(() => {
        setError(null);
        setHtmlData('');
        setDocBlobUrl('');
        setPdfJsonBlobUrl(null);
    }, [id]);
    useEffect(() => {
        if (!APRYSE_WEBVIEWER_SUPPORTED_FILES.includes(fileExtension?.toLowerCase()) || eigenDisplayViewContentId)
            return;
        (async () => {
            const token = await acquireToken();
            const API_URL = `${config.API_BASE_URL}/${typeDefId}('${itemId}')/Preview`;
            try {
                const request = {
                    method: 'POST',
                    headers: {
                        Authorization: `bearer ${token}`,
                    },
                };
                const response = await fetch(API_URL, request);
                if (!response.ok) {
                    setError('HTTP error occurred');
                    // Set alert to true manually since error does not change due when discovery is in progress
                    if (item.Status !== ItemManagementStatus.Destroyed)
                        setAlertOpen(true);
                    setDocBlobUrl('');
                }
                else {
                    if (response.headers.get('content-type') === 'application/octet-stream') {
                        const data = await response.blob();
                        const blobUrl = URL.createObjectURL(data);
                        setError(null);
                        setAlertOpen(false);
                        setDocBlobUrl(blobUrl);
                        setPdfJsonBlobUrl(undefined);
                        setHtmlData('');
                    }
                    else {
                        setError('Required header not present on response.');
                    }
                }
            }
            catch (error) {
                setError('An error occurred while fetching HTML data.');
            }
        })();
    }, [typeDefId, itemId, fileExtension, eigenDisplayViewContentId]);
    useEffect(() => {
        if (!APRYSE_WEBVIEWER_SUPPORTED_FILES.includes(fileExtension?.toLowerCase()) || !eigenDisplayViewContentId)
            return;
        (async () => {
            const token = await acquireToken();
            const API_URL = `${config.API_BASE_URL}/${typeDefId}('${itemId}')/EigenDisplayViewContent/$value`;
            try {
                const request = {
                    method: 'GET',
                    headers: {
                        Authorization: `bearer ${token}`,
                    },
                };
                const response = await fetch(API_URL, request);
                if (response.status !== 200) {
                    setError('HTTP error occurred');
                    // Set alert to true manually since error does not change due when discovery is in progress
                    if (item.Status !== ItemManagementStatus.Destroyed)
                        setAlertOpen(true);
                    setPdfJsonBlobUrl(undefined);
                }
                else {
                    if (response.headers.get('content-type') === 'application/octet-stream') {
                        const data = await response.json();
                        if (data) {
                            for (var key in data) {
                                const jsonString = JSON.stringify(data[key]);
                                const blob = new Blob([jsonString], { type: 'application/json' });
                                const blobUrl = URL.createObjectURL(blob);
                                const eigenViewerUrl = '/pdf-highlighter/index.html?obj=' + encodeURIComponent(blobUrl);
                                const iframeSrc = rowData ? eigenViewerUrl + '#search=' + encodeURIComponent(JSON.stringify(rowData)) : eigenViewerUrl;
                                setError(null);
                                setAlertOpen(false);
                                setPdfJsonBlobUrl(iframeSrc);
                                setHtmlData('');
                                setDocBlobUrl('');
                            }
                        }
                    }
                }
            }
            catch (error) {
                setError('An error occurred while fetching HTML data.');
            }
        })();
    }, [typeDefId, itemId, fileExtension, eigenDisplayViewContentId, rowData]);
    useEffect(() => {
        if (docBlobUrl && !eigenDisplayViewContentId && !hasInitialized) {
            WebViewer({
                path: '/lib',
                licenseKey: licenseKey,
                disabledElements: [
                    'printButton',
                    'downloadButton',
                    'saveAsButton',
                    'toggleCompareModeButton',
                    'toggleNotesButton',
                    'selectToolButton',
                ],
            }, viewer.current).then(async (instance) => {
                instanceRef.current = instance;
                instance.UI.loadDocument(docBlobUrl, { filename: 'file.' + fileExtension?.toLowerCase() });
                instance.UI.disableElements(['ribbons']);
                instance.UI.disableElements(['toolbarGroup-Shapes']);
                instance.UI.disableElements(['toolbarGroup-Edit']);
                instance.UI.disableElements(['toolbarGroup-Insert']);
                instance.UI.disableElements(['toolbarGroup-Annotate']);
                const Feature = instance.UI.Feature;
                instance.UI.disableFeatures([Feature.Copy, Feature.Print, Feature.selectToolButton]);
                const { documentViewer } = instance.Core;
                documentViewer.addEventListener('documentLoaded', () => {
                });
            });
            setHasInitialized(true);
        }
    }, [docBlobUrl, eigenDisplayViewContentId]);
    // Reloads the document when the docBlobUrl changes
    useEffect(() => {
        if (instanceRef?.current) {
            const iframes = document.getElementsByTagName('iframe');
            let webviewerIframe;
            for (let element of iframes) {
                if (String(element.id).includes('webviewer'))
                    webviewerIframe = element;
            }
            if (webviewerIframe && !docBlobUrl) {
                // Hide iframe if it exists but docBlobUrl is null
                webviewerIframe.style.display = 'none';
            }
            else if (webviewerIframe || docBlobUrl) {
                // Only show iframe if it exists
                if (webviewerIframe)
                    webviewerIframe.style.display = 'block';
                // Used to update WebViewer content
                instanceRef.current.UI.loadDocument(docBlobUrl, { filename: 'file.' + fileExtension?.toLowerCase() });
                instanceRef.current.UI.disableElements(['ribbons']);
                instanceRef.current.UI.disableElements(['toolbarGroup-Shapes']);
                instanceRef.current.UI.disableElements(['toolbarGroup-Edit']);
                instanceRef.current.UI.disableElements(['toolbarGroup-Insert']);
                instanceRef.current.UI.disableElements(['toolbarGroup-Annotate']);
                const Feature = instanceRef.current.UI.Feature;
                instanceRef.current.UI.disableFeatures([Feature.Copy, Feature.Print, Feature.selectToolButton]);
                const { documentViewer } = instanceRef.current.Core;
                documentViewer.addEventListener('documentLoaded', () => {
                });
            }
        }
    }, [docBlobUrl, eigenDisplayViewContentId, hasInitialized]);
    useEffect(() => {
        if (referenceType === 'PostBasedConversation' || referenceType === 'Email') {
            (async () => {
                const token = await acquireToken();
                const API_URL = `${config.API_BASE_URL}/Item('${itemId}')/Preview`;
                try {
                    const response = await fetch(API_URL, {
                        method: 'POST',
                        headers: {
                            Authorization: `bearer ${token}`,
                        },
                    });
                    if (!response.ok) {
                        setError('HTTP error occurred');
                        // Set alert to true manually since error does not change due when discovery is in progress
                        if (item.Status !== ItemManagementStatus.Destroyed)
                            setAlertOpen(true);
                        setHtmlData('');
                    }
                    else {
                        if (response.headers.get('content-type') === 'application/octet-stream') {
                            const blob = await response.blob();
                            const htmlData = await blob.text();
                            setError(null);
                            setAlertOpen(false);
                            setHtmlData(htmlData);
                            setDocBlobUrl('');
                            setPdfJsonBlobUrl(null);
                        }
                    }
                }
                catch (error) {
                    setError('An error occurred while fetching HTML data.');
                }
            })();
        }
    }, [referenceType, itemId]);
    useEffect(() => {
        if (!typeDefId || !itemId || !WebViewer || !APRYSE_WEBVIEWER_SUPPORTED_MEDIA.includes(fileExtension?.toLowerCase()))
            return;
        /** IMPORTANT: The embedded license key for Apryse technologies is the property of EncompaaS.  Use of this technology via EncompaaS does not imply any right to reverse engineer the key, use it for any other purpose or to distribute it.
        /* These activities will constitute a violation of EncompaaS licensing and will result in prosecution and likely result in compensation being soiught for this license breach.  Do not copy or distribute this license key. */
        /* licenseKey: 'EncompaaS Software Ltd (encompaas.cloud):OEM:Encompaas::B+:AMS(20240509):D7A59CCD0497C60A0360B13AC982537860612F9DE740EDA8DD047B8A9D242E8E22CAB6F5C7', /** IMPORTANT 2: THIS KEY IS ENCOMPAAS PROPERTY, DO NOT COPY OR DISTRIBUTE. */
        switch (fileExtension?.toLowerCase()) {
            case 'mp4':
            case 'ogg':
            case 'webm': {
                WebViewer({
                    path: '/lib',
                    enableAnnotations: false,
                }, viewer.current).then(async (instance) => {
                    // Extends WebViewer to allow loading HTML5 videos (mp4, ogg, webm).
                    const { loadVideo, } = await initializeVideoViewer(instance, {
                        license: '',
                    }, instance.UI.disableElements(['selectToolButton', 'video-ViewerOptions', 'menuButton']));
                    // Load a video at a specific url. Can be a local or public link
                    // If local it needs to be relative to lib/ui/index.html.
                    // Or at the root. (eg '/video.mp4')
                    const videoUrl = fileDetails?.ContentUrl?.Url;
                    loadVideo(videoUrl);
                    // Hide comment button 
                    const buttonElement = document.querySelector('.Button[data-element="toggleNotesButton"]');
                    if (!!buttonElement) {
                        buttonElement.style.display = 'none';
                    }
                });
                break;
            }
            case 'mp3':
            case 'wav':
            case 'FLAC': {
                WebViewer({
                    path: '/lib',
                    enableAnnotations: false,
                }, viewer.current).then(async (instance) => {
                    // Extends WebViewer to allow loading media files (.mp3, .mp4, ogg, webm, etc.)
                    const { loadAudio, } = await initializeAudioViewer(instance, {
                        license: '',
                    });
                    // Load a media element at a specific url. Can be a local or public link
                    // If local it needs to be relative to lib/ui/index.html.
                    // Or at the root. (eg '/audio.mp3')
                    const audioUrl = fileDetails?.ContentUrl?.Url;
                    loadAudio(audioUrl);
                });
                break;
            }
        }
    }, [fileDetails, viewer, typeDefId, itemId, fileExtension]);
    return { fileDetails, htmlData, docBlobUrl, pdfJsonBlobUrl, error };
};

export { APRYSE_WEBVIEWER_SUPPORTED_FILES, APRYSE_WEBVIEWER_SUPPORTED_MEDIA, useFileDetails };

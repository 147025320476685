import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import { DocumentHeaderFooter24Filled } from '@fluentui/react-icons';
import { memo } from 'react';
import { getExceptionLabel } from '../../../util/data-utils.js';
import { Box } from '../../Box.js';
import { useTheme } from '@mui/material';
import '../../../config.js';
import { DisposalRequestStatus, DisposalRequestStatusCaption } from '../../../types/requestTypes.js';
import { ItemGridQuery, DestroyItemsStatusCaption } from '../../../types/itemTypes.js';
import { convertObjectToArray } from '../../../util/array-utils.js';
import 'dayjs';
import 'react-redux';
import '@testing-library/react';
import '../../../themes/light.theme.js';
import '../../../store/store.js';
import 'redux-persist/integration/react';
import '../../../store/slices/applicationSlice.js';
import '../../../store/slices/downloadFileSlice.js';
import '../../../store/slices/itemModalSlice.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '@pdftron/webviewer';
import '../../../services/itemApi.js';
import '../../../services/requestApi.js';
import '../../../services/userApi.js';
import '../../../services/requestDetailsApi.js';
import '../../../auth/config.js';
import '../../../auth/AuthProvider.js';
import '@azure/msal-react';
import 'lodash';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../../../store/thunks/downloadFileThunk.js';
import { useItemExceptions } from '../../../hooks/useDisposalDetails.js';
import { useItemImage } from '../../../hooks/useItemImage.js';
import { Indicator } from '../../chip/Indicator.js';
import '../../chip/ItemManagementStatusChip.js';
import '../../chip/PerspectiveClassChip.js';
import { ImageWithFallback } from '../components/ImageWithFallback.js';
import { LinkWithArrow } from '../components/LinkWithArrow.js';

const ItemSummaryTemplate = ({ title, subTitle, information, imageId, itemId, disposalRequest, tags, onClick, onClickItems, onContextMenu, count, type, adminMode, itemGridType, filter, }) => {
    const { itemImage } = useItemImage({ id: imageId });
    const theme = useTheme();
    const { data: disposalDetailsExceptions, isSuccess: exceptionFetchSuccess } = useItemExceptions({
        itemId: itemId,
        disposalId: disposalRequest?.ID,
    });
    const exceptions = disposalDetailsExceptions != null &&
        convertObjectToArray(disposalDetailsExceptions).filter((x) => x[1] === true);
    const getExceptionChip = ({ request }) => {
        if (itemGridType === ItemGridQuery.DestroyItems)
            return;
        switch (true) {
            case !!exceptions && exceptions.length > 0 && adminMode:
                return [
                    ...new Set(exceptions
                        .filter((exception) => request?.Status !== DisposalRequestStatus.Rejected ||
                        (request?.Status === DisposalRequestStatus.Rejected &&
                            exception[0] !== 'RejectedInOtherRequest'))
                        .map((x) => {
                        if (request?.Status === DisposalRequestStatus.Rejected &&
                            x[0] === 'RejectedInOtherRequest')
                            return;
                        return getExceptionLabel(x[0]);
                    })),
                ].map((label, i) => {
                    if (label == '') {
                        return jsx(Fragment, {});
                    }
                    return jsx(Indicator, { label: label, color: 'warning' }, i);
                });
            default:
                return jsx(Fragment, {});
        }
    };
    const getItemStatusChip = ({ request, filter, }) => {
        switch (true) {
            case !!filter &&
                itemGridType === ItemGridQuery.DestroyItems &&
                adminMode:
                {
                    if (filter === DisposalRequestStatus.ItemDestroyComplete)
                        return (jsx(Indicator, { label: DestroyItemsStatusCaption.Destroyed, sx: {
                                backgroundColor: theme.palette.info.main,
                                color: theme.palette.common.white,
                                paddingTop: '0.063rem',
                            } }));
                    else if (filter === DisposalRequestStatus.Failed)
                        return (jsx(Indicator, { label: DestroyItemsStatusCaption.DestructionFailed, sx: {
                                backgroundColor: theme.palette.warning[900],
                                color: theme.palette.common.white,
                                paddingTop: '0.063rem',
                            } }));
                    return (jsx(Indicator, { label: DestroyItemsStatusCaption.DestructionInProgress, sx: { paddingTop: '0.063rem' } }));
                }
            case !!exceptions &&
                exceptions.length > 0 &&
                (request?.Status == DisposalRequestStatus.Complete ||
                    request?.Status == DisposalRequestStatus.InProgress) &&
                adminMode:
                return jsx(Indicator, { label: 'Destruction Deferred' });
            case request?.Status == DisposalRequestStatus.Approved:
                return (jsx(Indicator, { label: DisposalRequestStatusCaption.Approved, color: 'primary' }));
            case request?.Status == DisposalRequestStatus.InProgress:
                return jsx(Indicator, { label: DisposalRequestStatusCaption.InProgress });
            case request?.Status == DisposalRequestStatus.Complete &&
                !!exceptions &&
                !exceptions.length:
                return (jsx(Indicator, { label: DisposalRequestStatusCaption.Complete, color: 'primary' }));
            case request?.Status == DisposalRequestStatus.Rejected:
                return (jsx(Indicator, { label: DisposalRequestStatusCaption.Rejected, color: 'warning', isRejected: true }));
            case !!tags && (request?.Status == DisposalRequestStatus.New || !adminMode):
                return tags.map((tag, i) => jsx(Indicator, { label: tag }, i));
            default:
                return jsx(Fragment, {});
        }
    };
    return (jsxs(Box, { direction: 'column', gap: 'small', background: 'none', children: [jsxs(Box, { alignItems: 'center', direction: 'row', gap: 'small', background: 'none', onContextMenu: (e) => e.preventDefault(), style: { marginLeft: '1rem' }, children: [jsx(ImageWithFallback, { src: itemImage, sx: { mr: '0.6rem' } }), jsx(LinkWithArrow, { title: title, onClick: onClick, onContextMenu: onContextMenu })] }), jsxs(Box, { alignItems: 'center', direction: 'row', background: 'none', onContextMenu: (e) => e.preventDefault(), style: { marginLeft: '1rem', textOverflow: 'ellipsis' }, children: [jsx(DocumentHeaderFooter24Filled, { opacity: 0, style: {
                            marginRight: '0.6rem',
                            minWidth: '2rem',
                            alignSelf: 'flex-start',
                            marginTop: '2px',
                        } }), jsxs(Box, { direction: 'column', background: 'none', gap: 'medium', onContextMenu: (e) => e.preventDefault(), children: [count > 0 && (jsx(LinkWithArrow, { title: `${count} ${type == 'item' ? 'member' : ''} ${type}${count > 1 ? 's' : ''}`, onClick: onClickItems, onContextMenu: onContextMenu, variant: 'body2' })), jsxs(Box, { gap: 'small', background: 'none', direction: 'row', style: {
                                    textOverflow: 'ellipsis',
                                    flexWrap: 'wrap',
                                    marginRight: '1',
                                }, children: [getItemStatusChip({
                                        request: disposalRequest,
                                        filter,
                                    }), exceptionFetchSuccess &&
                                        getExceptionChip({ request: disposalRequest })] })] })] })] }));
};
const MemoizedItemSummaryTemplate = memo(ItemSummaryTemplate);

export { ItemSummaryTemplate, MemoizedItemSummaryTemplate };

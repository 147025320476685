import { jsxs, jsx } from 'react/jsx-runtime';
import { ChevronDown20Regular, ChevronUp20Regular } from '@fluentui/react-icons';
import { ListItemButton, Typography } from '@mui/material';
import { memo } from 'react';
import '../config.js';
import '../types/requestTypes.js';
import '../types/itemTypes.js';
import '../auth/config.js';
import '../auth/AuthProvider.js';
import '@azure/msal-react';
import 'dayjs';
import 'react-redux';
import '@testing-library/react';
import '../themes/light.theme.js';
import '../store/store.js';
import 'redux-persist/integration/react';
import '../store/slices/applicationSlice.js';
import '../store/slices/downloadFileSlice.js';
import '../store/slices/itemModalSlice.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '@pdftron/webviewer';
import '../services/itemApi.js';
import { useIcon } from '../hooks/useIcon.js';
import '../services/requestApi.js';
import '../services/userApi.js';
import '../services/requestDetailsApi.js';
import 'lodash';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../store/thunks/downloadFileThunk.js';
import { getOptionIcon } from './CollapsibleGroupedItem.js';

const CollapsibleGroupedParentItem = memo(({ groupName, expandedGroups, handleGroupToggle, businessCatalogNodeValues, }) => {
    // Get parent details
    // we can get image id and other details here.
    const parentDetails = businessCatalogNodeValues?.find((parent) => parent._Display === groupName);
    const { iconDetails } = useIcon({
        imageId: parentDetails?._ImageId,
    });
    return (jsxs(ListItemButton, { onClick: () => handleGroupToggle(groupName), sx: { gap: 0.625 }, children: [expandedGroups?.includes(groupName) ? (jsx(ChevronDown20Regular, { style: { color: '#212121' } })) : (jsx(ChevronUp20Regular, { style: { color: '#212121' } })), getOptionIcon(iconDetails), jsx(Typography, { variant: 'body2', children: groupName ?? 'All' })] }));
});

export { CollapsibleGroupedParentItem };
